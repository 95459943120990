<template>
  <div class="container">
    <el-container>
      <el-header >
        <el-row
          type="flex"
          :gutter="10"
          style="width: 100vw"
        >
          <el-col :span="3">
            <el-input
              v-model="filterOption.countryCode"
              :placeholder="$t('harbour.countryCode')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="filterOption.atThreeCode"
              :placeholder="$t('harbour.atThreeCode')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="filterOption.atName"
              :placeholder="$t('harbour.atName')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="filterOption.atNameEn"
              :placeholder="$t('harbour.atNameEn')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="filterOption.stFiveCode"
              :placeholder="$t('harbour.stFiveCode')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="filterOption.lineCode"
              :placeholder="$t('harbour.lineCode')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col
            :span="4"
            :offset="2"
          >
            <div style="float: right">
              <el-button
                type="primary"
                size="mini"
                @click="doSearch"
              >{{
                $t("system.search")
                }}</el-button>
              <el-button
                type="warning"
                size="mini"
                @click="doReset"
              >{{
                $t("system.reset")
                }}</el-button>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <div class="tableHeader">
          <!-- <el-button size="mini">{{ $t("system.import") }}</el-button> -->
          <el-button
            size="mini"
            @click="showCreateDialog"
          >{{
            $t("system.create")
            }}</el-button>
          <!-- <el-button size="mini">{{ $t("system.delete") }}</el-button> -->
        </div>
        <el-table
          :data="tableData"
          ref="accountTable"
          border
          :height="tHeight"
          v-loading="isTableLoading"
          @selection-change="onTableSelectionChange"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            width="40"
            fixed
          ></el-table-column>
          <el-table-column
            type="index"
            :label="$t('system.index')"
            width="60"
            align="center"
            fixed
          ></el-table-column>
          <el-table-column
            prop="countryCode"
            :label="$t('harbour.countryCode')"
            align="center"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="atThreeCode"
            :label="$t('harbour.atThreeCode')"
            align="center"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="atName"
            :label="$t('harbour.atName')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="airTransport"
            :label="$t('harbour.atNameEn')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="seaTransportCode"
            :label="$t('harbour.stFiveCode')"
            align="center"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="otherLine"
            :label="$t('harbour.lineCode')"
            align="center"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="remark"
            :label="$t('harbour.remark')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('system.operation')"
            fixed="right"
            align="center"
            width="150"
          >
            <template v-slot="scope">
              <el-button
                type="text"
                size="mini"
                icon="el-icon-edit"
                @click="edit(scope.row)"
              >{{ $t("system.edit") }}</el-button>
              <!-- <el-button
                type="text"
                style="color:#E6A23C;"
                size="mini"
                icon="el-icon-delete"
                @click="del(scope.row)"
              >{{ $t("system.delete") }}</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div style="float: right; margin-top: 10px">
          <el-pagination
            @size-change="onTableSizeChange"
            @current-change="onTablePageChange"
            :current-page="filterOption.pageNo"
            :page-sizes="[10, 50, 100, 200]"
            layout="total,sizes,prev, pager, next"
            :total="filterOption.total"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>

    <create-harbour-dialog
      v-if="isShowCreateDialog"
      :isShowDialog="isShowCreateDialog"
      @onDialogClose="closeCreateDialog"
    />
    <update-harbour-dialog
      v-if="isShowUpdateDialog"
      :isShowDialog="isShowUpdateDialog"
      :rowInfo="currentRow"
      @onDialogClose="closeUpdateDialog"
    />
  </div>
</template>

<script>
import baseServer from "./source/basedata";
import mixin from "@/utils/mixin.js";
import CreateHarbourDialog from "./dialogs/createHarbour";
import UpdateHarbourDialog from "./dialogs/updateHarbour";
export default {
  name:"iata",
  components: {
    CreateHarbourDialog,
    UpdateHarbourDialog
  },
  mixins: [mixin],
  data() {
    return {
      isTableLoading: false,
      isShowCreateDialog: false,
      isShowUpdateDialog: false,
      filterOption: {
        countryCode: null,
        atThreeCode: null,
        atName: null,
        atNameEn: null,
        stFiveCode: null,
        lineCode: null,

        pageNo: 1,
        pageSize: 10,
        total: 0
      },

      tableData: [],
      tableSelection: [],
      currentRow: null
    };
  },
  computed: {
    tHeight() {
      return this.tableHeight - 135;
    }
  },
  mounted() {},
  methods: {
    doSearch() {
      baseServer
        .queryHarbour({
          ...this.filterOption,
          current: this.filterOption.pageNo,
          size: this.filterOption.pageSize
        })
        .then(res => {
          this.tableData = res.data ? res.data.records : [];
          this.filterOption.total = res.data.total;
        });
    },
    edit(row) {
      this.currentRow = row;
      this.isShowUpdateDialog = true;
    },
    del(row) {
      console.log(row);
    },
    onTableSelectionChange(val) {
      this.tableSelection = val;
    },
    onTableSizeChange(size) {
      this.filterOption = {...this.filterOption,  pageSize: size,pageNo:1}
      this.doSearch()
     
    },
    onTablePageChange(page) {
      this.filterOption = {...this.filterOption,  pageNo: page,}
      this.doSearch()
    },
    showCreateDialog() {
      this.isShowCreateDialog = true;
    },
    closeCreateDialog() {
      this.isShowCreateDialog = false;
    },
    closeUpdateDialog() {
      this.isShowUpdateDialog = false;
    },
    doReset(){
      this.filterOption =  {
        countryCode: null,
        atThreeCode: null,
        atName: null,
        atNameEn: null,
        stFiveCode: null,
        lineCode: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  height: calc(100% - 20px);
  background-color: white;
  overflow-y: scroll;
  padding: 10px;

  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    :deep(el-form-item) {
      margin-right: 10px;
    }
  }

  :deep(.el-main) {
    padding: 0 20px;
    .tableHeader {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 48px;
    }
  }
}
</style>
