<template>
  <el-dialog
    :title="$t('harbour.createHarbour')"
    v-model="isShow"
    width="700px"
    :before-close="handleClose"
  >
    <el-form
      ref="harbourForm"
      :inline="true"
      :model="harbourInfo"
      size="mini"
      :rules="harbourRules"
    >
      <div class="row">
        <el-form-item
          :label="$t('harbour.countryCode')"
          prop="countryCode"
          label-width="120px"
        >
          <el-input
            v-model.trim="harbourInfo.countryCode"
            :placeholder="$t('system.pleaseInput') + $t('harbour.countryCode')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('harbour.atThreeCode')"
          prop="atThreeCode"
          label-width="120px"
        >
          <el-input
            v-model.trim="harbourInfo.atThreeCode"
            :placeholder="$t('system.pleaseInput') + $t('harbour.atThreeCode')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('harbour.atName')"
          prop="atName"
          label-width="120px"
        >
          <el-input
            v-model.trim="harbourInfo.atName"
            :placeholder="$t('system.pleaseInput') + $t('harbour.atName')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('harbour.atNameEn')"
          prop="airTransport"
          label-width="120px"
        >
          <el-input
            v-model.trim="harbourInfo.airTransport"
            :placeholder="$t('system.pleaseInput') + $t('harbour.atNameEn')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('harbour.stFiveCode')"
          prop="seaTransportCode"
          label-width="120px"
        >
          <el-input
            v-model.trim="harbourInfo.seaTransportCode"
            :placeholder="$t('system.pleaseInput') + $t('harbour.stFiveCode')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('harbour.lineCode')"
          prop="otherLine"
          label-width="120px"
        >
          <el-input
            v-model.trim="harbourInfo.otherLine"
            :placeholder="$t('system.pleaseInput') + $t('harbour.lineCode')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('harbour.remark')"
          prop="remark"
          label-width="120px"
        >
          <el-input
            v-model.trim="harbourInfo.remark"
            :placeholder="$t('system.pleaseInput') + $t('harbour.remark')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="handleClose"
          size="mini"
        >{{
          $t("system.cancel")
          }}</el-button>
        <el-button
          type="primary"
          @click="doCreateHarbour"
          size="mini"
        >{{
          $t("system.confirm")
          }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import baseServer from "../source/basedata";
export default {
  props: ["isShowDialog"],
  emits: ["onDialogClose"],
  data() {
    return {
      harbourInfo: {
        countryCode: null,
        atThreeCode: null,
        atName: null,
        airTransport: null,
        seaTransportCode: null,
        otherLine: null,
        remark: null
      },
      harbourRules: {
        countryCode: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("harbour.countryCode"),
            trigger: "blur"
          }
        ],
        atThreeCode: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("harbour.atThreeCode"),
            trigger: "blur"
          }
        ],
        atName: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("harbour.atName"),
            trigger: "blur"
          }
        ],
        airTransport: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("harbour.atNameEn"),
            trigger: "blur"
          }
        ],
        otherLine: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("harbour.lineCode"),
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    isShow() {
      return this.isShowDialog;
    }
  },
  methods: {
    handleClose() {
      this.$emit("onDialogClose");
    },
    doCreateHarbour() {
      this.$refs["harbourForm"].validate(valid => {
        if (valid) {
          baseServer.addHarbour(this.harbourInfo).then(() => {
            this.$confirm(
              this.$t("harbour.createSuccess"),
              this.$t("system.continue") + "?",
              {
                confirmButtonText: this.$t("system.confirm"),
                cancelButtonText: this.$t("system.cancel"),
                type: "success"
              }
            )
              .then(() => {
                this.$refs["harbourForm"].resetFields();
              })
              .catch(() => {
                this.handleClose();
              });
          });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :deep(.el-form-item__label) {
    font-size: 12px;
  }
  :deep(.el-input--mini) {
    max-width: 180px;
  }
}
</style>
